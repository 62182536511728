import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Toptitle from "../../components/toptitle"

const Construct = () => (
  <Layout>
    <SEO title="納入実績" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Works</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">納入実績</h1>
        </div>
        <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
          <div className="mt-8 flex flex-col bg-secondery">
            <StaticImage
              src="../../images/works/work-m.jpg"
              alt="モールディングの事例"
              className="aspect-[4/3] w-full"
            />
            <div className="flex flex-grow flex-col py-8 px-12">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                モールディング
              </h2>
              <p className="font-gothic leading-loose">
                百貨店をはじめとする商業施設やホテルなどの天井廻り縁のご利用が多く、
                <br />
                曲面仕様や大量発注にも対応してまいりました。
                <br />
                図面作成による割付や施工性を考慮した製作にもご対応いたします。
              </p>
              <div className="mt-auto text-right font-mincho">
                <span className="mt-8 inline-block">納入実績 一例</span>
                <ul className="mt-4 mr-0 ml-auto grid w-full list-none grid-cols-2 gap-1 text-sm lg:grid-cols-3">
                  <Link to="/works/takashimaya" className="text-primary">
                    <li className="inline">高島屋 大阪店</li>
                  </Link>
                  <li className="inline">丸の内1丁目計画</li>
                  <li className="inline">JPタワー</li>
                  <li className="inline">三井住友銀行本社</li>
                  <li className="inline">読売新聞本社</li>
                  <li className="inline">福岡三越</li>
                  <li className="inline">東京ミッドタウン</li>
                  <li className="inline">ペニンシュラホテル</li>
                  <li className="inline">新国立競技場</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 flex w-full flex-col bg-secondery">
            <StaticImage
              src="../../images/works/work-r.jpg"
              alt="ルーバーの事例"
              className="aspect-[4/3] w-full"
            />
            <div className="flex flex-grow flex-col py-8 px-12">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                ルーバー
              </h2>
              <p className="font-gothic leading-loose">
                商業施設、マンションエントランスやオフィスなど幅広く納品実績があります。
                アルミや木目調などの仕上げまでもご対応いたします。
              </p>
              <div className="mt-auto text-right font-mincho">
                <span className="mt-8 inline-block">納入実績 一例</span>
                <ul className="mt-4 mr-0 ml-auto grid w-full list-none grid-cols-2 gap-1 text-sm xl:grid-cols-3">
                  <li className="inline">丸紅本社</li>
                  <li className="inline">東京スカイツリー</li>
                  <li className="inline">高崎芸術劇場</li>
                  <li className="inline">オマーン大使館</li>
                  <li className="inline">法政大学</li>
                  <li className="inline">シェラトンホテル</li>
                  <li className="inline">東京ベイ舞浜ホテル</li>
                  <li className="inline">上智大学</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 flex w-full flex-col bg-secondery md:mt-0">
            <StaticImage
              src="../../images/works/work-mikiri.jpg"
              alt="見切りの事例"
              className="aspect-[4/3] w-full"
            />
            <div className="flex flex-grow flex-col py-8 px-12">
              <h2 className="py-2 font-mincho text-2xl text-primary">見切り</h2>
              <p className="font-gothic leading-loose">
                店舗やオフィスなどのクロスの差し目地や壁見切り、
                <br />
                天井-壁の入隅見切りなどをはじめ、その他にも耐熱性能を活かし、
                <br />
                間接照明の周辺材料やカーテンボックスなどにご利用いただいております。
              </p>
              <div className="mt-auto text-right font-mincho">
                <span className="mt-8 inline-block">納入実績 一例</span>
                <ul className="mt-4 mr-0 ml-auto grid w-full list-none grid-cols-2 gap-1  text-sm xl:grid-cols-3">
                  <li className="inline">渋谷ヒカリエ</li>
                  <li className="inline">東京競馬場</li>
                  <li className="inline">キッザニア</li>
                  <li className="inline">GODIVA</li>
                  <li className="inline">表参道ヒルズ</li>
                  <li className="inline">淡路ロイヤルホテル</li>
                  <li className="inline">ミラコスタ</li>
                  <li className="inline">新宿モード学園</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 flex w-full flex-col bg-secondery md:mt-0">
            <StaticImage
              src="../../images/works/work-p.jpg"
              alt="デザインパネルの事例"
              className="aspect-[4/3] w-full"
            />
            <div className="flex flex-grow flex-col py-8 px-12">
              <h2 className="py-2 font-mincho text-2xl text-primary">
                デザインパネル
              </h2>
              <p className="font-gothic leading-loose">
                商業施設や空港をはじめとする公共機関に不燃装飾材としてご利用いただいております。
                <br />
                壁面装飾材としてではなく、アートパネルとしての納品実績もあります。
              </p>
              <div className="mt-auto text-right font-mincho">
                <span className="mt-8 inline-block">納入実績 一例</span>
                <ul className="mt-4 mr-0 ml-auto grid w-full list-none grid-cols-2 gap-1 text-sm xl:grid-cols-3">
                  <Link to="/works/haneda" className="text-primary">
                    <li className="inline">羽田空港</li>
                  </Link>
                  <Link to="/works/narita" className="text-primary">
                    <li className="inline">成田空港</li>
                  </Link>
                  <Link to="/works/kakigi" className="text-primary">
                    <li className="inline">柿木記念館</li>
                  </Link>
                  <li className="inline">銀座ニコラスG</li>
                  <li className="inline">ティファニー</li>
                  <li className="inline">梅田阪急百貨店</li>
                  <li className="inline">パナソニック研究所</li>

                  <li className="inline">TBS</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 flex w-full flex-col bg-secondery md:mt-0">
            <StaticImage
              src="../../images/works/work-s.jpg"
              alt="サインの事例"
              className="aspect-[4/3] w-full"
            />
            <div className="flex flex-grow flex-col py-8 px-12">
              <h2 className="py-2 font-mincho text-2xl text-primary">サイン</h2>
              <p className="font-gothic leading-loose">
                店舗やオフィスのサインなども製作しております。
              </p>
              <div className="mt-auto text-right font-mincho">
                <span className="mt-8 inline-block">納入実績 一例</span>
                <ul className="mt-4 mr-0 ml-auto grid w-full list-none grid-cols-2 gap-1 text-sm xl:grid-cols-3">
                  <Link to="/works/suruga" className="text-primary">
                    <li className="inline">駿河湾沼津SA</li>
                  </Link>
                  <Link to="/works/ecute" className="text-primary">
                    <li className="inline">エキュート東京</li>
                  </Link>
                  <li className="inline">ロレックス</li>
                  <li className="inline">渋谷ロフト</li>
                  <li className="inline">Dennys</li>
                  <li className="inline">ロエベ</li>
                  <li className="inline">カルティエ</li>
                  <li className="inline">エルメス</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default Construct
